import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://c19693eea63fbe7ce4aba255d4263e49@o4507627669159936.ingest.de.sentry.io/4507627678924880",
  debug: false,
  environment: "prod",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});